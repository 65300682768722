<template>
    <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Mapping Customer</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            
            <div class="mb-3">
                <label for="code" class="form-label">Excel File</label>
                <bs-file :fileName="fileName" @success="onUploadSuccess" @error="onUploadError" @clear="onUploadClear" />
            </div>
            <button class="btn btn-sm btn-primary" @click="downloadTemplate"><i class="bi bi-download"></i> Download Template</button>
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="save"><i class="bi bi-check-circle"></i> Save Excel</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import MappingAircraftService from "@/services/mapping-aircraft-service.js";
export default {
    data: function() {
        return {
            file: null,
            errorMessages: null,
            hasErrors: false
        }
    },
    computed: {
        fileName() {
            if (this.file === null || this.file === undefined) return "";
            return this.file.originalFileName;
        }
    },
    methods: {
        async onUploadSuccess(value) {
            this.file = value;
        },
        async onUploadError(error) {
            this.file = null;
            console.log(error);
        },
        async onUploadClear() {
            this.file = null;
        },
        async onPopupClosed() {
            this.$router.push('/mapping-aircraft');
        },
        async downloadTemplate() {
          try {
            this.$store.commit('progressCounterQueueIncrement');
            await MappingAircraftService.downloadExcel();
          }
          finally {
            this.$store.commit('progressCounterQueueDecrement');
          }
        },
        async readExcelResult(data) {
            const excelRows = data.excelRows;
            const mappingAircrafts = excelRows['mapping-customer'];
            if (mappingAircrafts === undefined || mappingAircrafts === null || mappingAircrafts.length === 0) {
                return;
            } 

            this.errorMessages = Array();
            this.hasErrors = false;
            for (let i = 0; i < mappingAircrafts.length; i++) 
            {
                const mappingAircraft = mappingAircrafts[i];
                if (mappingAircraft.errorMessages === undefined || mappingAircraft.errorMessages === null || mappingAircraft.errorMessages.length === 0) {
                    return;
                }

                if (this.errorMessages["_ERRORS_"] === undefined) {
                    this.errorMessages = {
                        "_ERRORS_": []
                    };
                }

                for (let j = 0; j < mappingAircraft.errorMessages.length; j++) 
                {
                    let errorMessage = mappingAircraft.errorMessages[j];
                    errorMessage = `Worksheet ${mappingAircraft.worksheetName} at row ${mappingAircraft.rowNumber}: ${errorMessage}`;
                    this.errorMessages["_ERRORS_"].push(errorMessage);
                    this.hasErrors = true;
                }
            }
        },
        async save() 
        {
            let isCompleted = false;
            try {
                this.$store.commit('progressCounterQueueIncrement');
                if (this.file === undefined || this.file === null)
                {
                  alert("File is required.");
                }
  
                const result = await MappingAircraftService.uploadExcel(this.file);
                if (result.status)
                {
                  this.errorMessages = null;
                  await this.readExcelResult(result.response.data.data);
                  if (this.hasErrors)
                  {
                    return;
                  }
                  
                  this.$store.commit('progressCounterQueueDecrement');
                  isCompleted = true;
                  alert("Upload Success.");
                  this.close();
                }
                else
                {
                  this.errorMessages = result.errorMessages;
                }
            }
            finally {
              if (!isCompleted) 
              {
                this.$store.commit('progressCounterQueueDecrement');
              }
            }
        },
        close() {
            this.$refs.form.closePopup();
        }
    }
}
</script>