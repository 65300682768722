import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter) 
    {
        const response = await httpService.get(`mappingaircrafts`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`mappingaircrafts/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        model = this._setDefaultValue(model);
        const response = await httpService.post('mappingaircrafts', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        model = this._setDefaultValue(model);
        const response = await httpService.update('mappingaircrafts', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('mappingaircrafts', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async uploadExcel(fileInfo) {
        const response = await httpService.post('mappingaircrafts/UploadExcel', fileInfo);
        if (!response.status) {
            response.data = false;
        }

        response.data = true;

        return response;
    },

    async downloadExcel() {
        await httpService.downloadFile('mappingaircrafts/DownloadExcel');
    },

    default() {
        return {
            id: 0,

            airlineId: 0,
            airlineCode: null,
            airlineName: null,
            airlineLabel: null,

            locationId: 0,
            locationCode: null,

            soldToId: 0,
            soldToCode: null,
            soldToName: null,
            soldToLabel: null,
            
            shipToId: 0,
            shipToCode: null,
            shipToName: null,
            shipToLabel: null,
            
            payerId: 0,
            payerCode: null,
            payerName: null,
            payerLabel: null,

            billToId: 0,
            billToCode: null,
            billToName: null,
            billToLabel: null
        };
    },

    _setDefaultValue(jsonData) {
        if (jsonData === undefined || jsonData === null) {
            return this.default();
        }

        if (jsonData.airlineId === null || jsonData.airlineId === '') {
            jsonData.airlineId = 0;
        }
        if (jsonData.locationId === null || jsonData.locationId === '') {
            jsonData.locationId = 0;
        }
        if (jsonData.soldToId === null || jsonData.soldToId === '') {
            jsonData.soldToId = 0;
        }
        if (jsonData.shipToId === null || jsonData.shipToId === '') {
            jsonData.shipToId = 0;
        }
        if (jsonData.payerId === null || jsonData.payerId === '') {
            jsonData.payerId = 0;
        }
        if (jsonData.billToId === null || jsonData.billToId === '') {
            jsonData.billToId = 0;
        }
        
        return jsonData;
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        
        result.airlineId = jsonData.airlineId;
        result.airlineCode = jsonData.airlineCode;
        result.airlineName = jsonData.airlineName;
        result.airlineLabel = jsonData.airlineCode + ' - ' + jsonData.airlineName;
        
        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode;

        result.soldToId = jsonData.soldToId;
        result.soldToCode = jsonData.soldToCode;
        result.soldToName = jsonData.soldToName;
        result.soldToLabel = jsonData.soldToCode + ' - ' + jsonData.soldToName;

        result.shipToId = jsonData.shipToId;
        result.shipToCode = jsonData.shipToCode;
        result.shipToName = jsonData.shipToName;
        result.shipToLabel = jsonData.shipToCode + ' - ' + jsonData.shipToName;
        
        result.payerId = jsonData.payerId;
        result.payerCode = jsonData.payerCode;
        result.payerName = jsonData.payerName;
        result.payerLabel = jsonData.payerCode + ' - ' + jsonData.payerName;

        result.billToId = jsonData.billToId;
        result.billToCode = jsonData.billToCode;
        result.billToName = jsonData.billToName;
        result.billToLabel = jsonData.billToCode + ' - ' + jsonData.billToName;

        return result;
    }
});